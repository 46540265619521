<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Generate Exports</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page"> <router-link to="/employees-payroll"> Employees Payroll </router-link></li>
                <li class="breadcrumb-item active" aria-current="page"> Generate Exports </li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            
            <div class="p-3">
                <b-row>
                    <b-col lg="7" sm="12" class="mb-0">
                        <div class="form-group row">
                            <label
                                class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                            >Employees</label>
                            <div class="col-lg-9 col-md-10 col-sm-12">
                                <span class="mx-4">
                                    <input type="radio" v-model="payroll_format.employee_selection_mode" value="all" class="form-check-input"/>
                                    <label class="form-check-label">All</label>
                                </span>

                                <span class="mx-4">
                                    <input type="radio" v-model="payroll_format.employee_selection_mode" value="individual" class="form-check-input"/>
                                    <label class="form-check-label">Individual</label>
                                </span>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="7" sm="12" class="mb-1rem" v-if="payroll_format.employee_selection_mode=='individual'">
                        <div class="form-group row">
                            <div class="col-lg-3 col-md-2 col-sm-12"></div>
                            <div class="col-lg-9 col-md-10 col-sm-12">
                                <EmployeeList @emp_ids="getEmployees" multiple :closeOnSelect="false" allowEmpty />
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="7" sm="12" class="mb-1rem">
                        <div class="form-group row">
                            <label
                                class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                            >Year</label>
                            <div class="col-lg-9 col-md-10 col-sm-12">
                                <b-form-select
                                    v-model="payroll_format.year"
                                    :options="years"
                                    disabled-field="notEnabled"
                                ></b-form-select>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="7" sm="12" class="mb-1rem">
                        <div class="form-group row">
                            <label
                                class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                            >Month</label>
                            <div class="col-lg-9 col-md-10 col-sm-12">
                                <b-form-select
                                    v-model="payroll_format.month"
                                    :options="months"
                                    disabled-field="notEnabled"
                                ></b-form-select>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="7" class="mb-1rem">
                        <div class="form-group row">
                            <label
                                class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                            >Payroll Format</label>
                            <div class="col-lg-9 col-md-10 col-sm-12">
                                <b-form-select
                                    v-model="payroll_format.format"
                                    :options="formats"
                                    disabled-field="notEnabled"
                                ></b-form-select>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="7" class="mb-1rem">
                        <div class="form-group row">
                            <label
                                class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                            ></label>
                            <div class="col-lg-9 col-md-10 col-sm-12">
                                <b-form-checkbox @change="enableManuallyGeneratePayrollModal"  v-model="payroll_format.generate_payroll" name="checkbox-1" :value="1" :unchecked-value="0">
                                    Manually Generate Payroll
                                </b-form-checkbox>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="12" class="mb-1rem">
                        <button class="btn btn-primary " v-if="hasPermission('CAN_DOWNLOAD_PAYROLL')" @click="generateExportConfirmationModal">
                            Generate Exports
                        </button>
                        <router-link :to="{ name: 'download-exports' }" class="btn btn-primary float-right" v-if="hasPermission('CAN_MANAGE_PAYROLL')">
                            Download Exports
                        </router-link>
                    </b-col>
                </b-row>
            </div>        
        </b-container>

        <!-- Manually Generate Payroll Confirmation Modal -->
        <b-modal
            id="manual_generate_payroll_modal"
            title="Manually Generate Payroll Confirmation"
            centered
            size="md"
            no-close-on-esc
            no-close-on-backdrop
            @hide="cancelManualGeneratePayrollModal"
        >
            <p class="float-left">Payroll is automatically generated every month. Manually generating payroll will overwrite already generated Payroll. Are you sure you want to manually generate Payroll?</p>
            <div slot="modal-footer" class="w-100" >
                <b-button class="mt-3 float-right" @click="cancelManualGeneratePayrollModal">No</b-button>
                <b-button
                    class="mt-3 float-right btn btn-primary"
                    variant="danger"
                    style="margin-right:10px;"
                    @click="confirmManualGeneratePayrollModal"
                >Yes</b-button>
            </div>
        </b-modal>

        <!-- Generate Exports Confirmation Modal -->
        <b-modal
            id="generate_exports_modal"
            title="Generate Exports Confirmation"
            centered
            size="md"
            no-close-on-esc
            no-close-on-backdrop
        >
            <p class="float-left">Are you sure you want to Generate exports ?</p>
            <div slot="modal-footer" class="w-100" >
                <b-button class="mt-3 float-right" @click="$bvModal.hide('generate_exports_modal')">No</b-button>
                <b-button
                    class="mt-3 float-right btn btn-primary"
                    variant="danger"
                    style="margin-right:10px;"
                    @click="generatePayrollExcel"
                >Yes</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import EmployeeList from "@/components/EmployeeList.vue";
export default {
    components: {
        NotFound,
        EmployeeList
    },
    data() {
        return {
            payroll_format: {
                year: new Date().getFullYear(),
                month: '',
                format:"",
                employee_selection_mode: 'all',
                employees: [],
                generate_payroll: 0
            },
            api_error: "",
            employees: [],
            months: [
                { value: 1, text: 'January' },
                { value: 2, text: 'Febuary' },
                { value: 3, text: 'March' },
                { value: 4, text: 'April' },
                { value: 5, text: 'May' },
                { value: 6, text: 'June' },
                { value: 7, text: 'July' },
                { value: 8, text: 'August' },
                { value: 9, text: 'September' },
                { value: 10, text: 'October' },
                { value: 11, text: 'November' },
                { value: 12, text: 'December' }
            ],
            generate_payroll_confirmation: 0,
            filter: null,
            access: true,
            years: [],
            total_years:3,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            formats: [],
            host: this.$api.host_url,
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_PAYROLL");
        this.getPreviousMonth();
        this.getYears();
        this.payrollFormat();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getPreviousMonth(){
            var  months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
            var d = new Date();
            let get_month = months[d.getMonth()];
            if (get_month == 1){
                this.payroll_format.year = (new Date()).getFullYear() -1;
                this.payroll_format.month = 12;
            } else {
                this.payroll_format.month =get_month-1;
            }
        },

        getMonthName(number){
            let month_number = parseInt(number) -1
            let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[month_number];
        },

        payrollFormat () {
            let formats = this.$store.getters.getAppSetting("payroll", "payroll_export_formats");
            formats.forEach(format => {
                this.formats.push({
                    value: format.name,
                    text: format.name
                });
            });
            this.payroll_format.format = this.formats[0].value;
        },

        getYears() {
            const currentYear = (new Date()).getFullYear()+1;
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
            this.onPageLoad();
        },

        getDefaultFilters() {
            let api_params = {
                year: this.payroll_format.year,
                month: this.payroll_format.month
            };
            return api_params;
        },

        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.deleteEmptyKeys(this.payroll_format);
            this.payroll_format.year = api_params.year;
            this.payroll_format.month = api_params.month;
            this.$router.replace({
                    path: this.$route.params[0],
                    query: {year: api_params.year, month: api_params.month}
                }).catch(()=>{})
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },

        
        onSelection() {
            this.getExcellExport(this.getDefaultFilters());
        },

        getEmployees: function (employees) {
            this.payroll_format.employees = employees;
        },

        generateExportConfirmationModal() {
            this.$root.$emit("bv::show::modal", 'generate_exports_modal');
        },

        generatePayrollExcel(){
            const query = this.getAuthHeaders();
            let employee_ids = [];
            if (this.payroll_format.employees) {
                employee_ids = this.payroll_format.employees.map(employee => employee.id);
            }
            query["params"] = {
                year: this.payroll_format.year,
                month: this.payroll_format.month,
                format: this.payroll_format.format,
                employee_selection_mode: this.payroll_format.employee_selection_mode,
                employee_ids: employee_ids,
                generate_payroll: this.payroll_format.generate_payroll,
                response_type: "xlsx",
            };
            this.axios
                .get(this.$api.export_payroll, query)
                .then(() => {
                    this.$router.push({ name: 'download-exports', query: { year: this.payroll_format.year, month: this.payroll_format.month } });
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.$root.$emit("bv::hide::modal", 'generate_exports_modal');
                    this.showAlert();
                });
        },

        cancelManualGeneratePayrollModal() {
            if (!this.generate_payroll_confirmation) {
                this.payroll_format.generate_payroll = this.generate_payroll_confirmation;
            }
            this.$root.$emit("bv::hide::modal", 'manual_generate_payroll_modal');
        },

        confirmManualGeneratePayrollModal() {
            this.generate_payroll_confirmation = this.payroll_format.generate_payroll;
            this.$root.$emit("bv::hide::modal", 'manual_generate_payroll_modal');
        },

        enableManuallyGeneratePayrollModal() {
            if (this.payroll_format.generate_payroll) {
                this.$root.$emit("bv::show::modal", 'manual_generate_payroll_modal');
                return;
            } 
            this.generate_payroll_confirmation = this.payroll_format.generate_payroll;
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
.pointer-cursor {
    cursor:pointer !important;
}

</style>
